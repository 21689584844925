import cookieManager from './cookieManager';
import languages from './languages';
import { siteData } from '../../../data/index';

// requires FRONTend language id
function languageChange(langId) {
    // get the details of the language id
    const langDetails = languages.frontendLanguageIdConvert(langId);

    // if successful
    if (langDetails.success === true) {
        // set frontend cookie
        cookieManager.setLangLearnFrontend(langDetails.frontendId);

        // set backend cookie
        cookieManager.setLangLearnBackend(langDetails.backendId);
    }
}

function whoLearningChange(id) {
    // as long as this isn't empty, set cookie value
    if (id) {
        cookieManager.setWhoLearning(id);
    }
}

function convertWhoLearningIdToText(id) {
    const ret = {
        success: false,
        backendId: null,
        text: null,
    };
    const intId = parseInt(id, 10);

    siteData.whoLearning.forEach((element) => {
        if (element.backendId === intId) {
            ret.success = true;
            ret.backendId = element.backendId;
            ret.nameEnglish = element.nameEnglish;
        }
    });

    return ret;
}

function whyLearnChange(id) {
    // as long as this isn't empty, set cookie value
    if (id) {
        cookieManager.setWhyLearn(id);
    }
}

function convertWhyLearningIdToText(id) {
    const ret = {
        success: false,
        backendId: null,
        text: null,
    };
    const intId = parseInt(id, 10);

    siteData.whyLearn.forEach((element) => {
        if (element.backendId === intId) {
            ret.success = true;
            ret.backendId = element.backendId;
            ret.nameEnglish = element.nameEnglish;
        }
    });

    return ret;
}

function groupChange(id) {
    // as long as this isn't empty, set cookie value
    if (id) {
        cookieManager.setGroup(id);
    }
}

function convertGroupIdToText(id) {
    const ret = {
        success: false,
        backendId: null,
        text: null,
    };
    const intId = parseInt(id, 10);

    siteData.group.forEach((element) => {
        if (element.backendId === intId) {
            ret.success = true;
            ret.backendId = element.backendId;
            ret.nameEnglish = element.nameEnglish;
        }
    });

    return ret;
}

function emailChange(eml) {
    // as long as this isn't empty, set cookie value
    if (eml) {
        cookieManager.setMarketingEmail(eml);
    }
}

const localSettings = {
    language: languageChange,
    whoLearning: whoLearningChange,
    convertWhoLearningToText: convertWhoLearningIdToText,
    convertWhyLearningToText: convertWhyLearningIdToText,
    convertGroupToText: convertGroupIdToText,
    whyLearn: whyLearnChange,
    group: groupChange,
    email: emailChange,
};

export default localSettings;

const group = [
    {
        backendId: 1,
        nameEnglish: 'No',
    },
    {
        backendId: 2,
        nameEnglish: 'Yes, an educational institution',
    },
    {
        backendId: 3,
        nameEnglish: 'Yes, a business',
    },
    {
        backendId: 4,
        nameEnglish: 'Yes, a nonprofit',
    },
    {
        backendId: 5,
        nameEnglish: 'Yes, another type of group',
    },
];

module.exports = group;

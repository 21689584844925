import cookieManager from '../modules/cookieManager';
import localSettings from '../modules/localSettings';
import ui from '../modules/ui';
import { siteData } from '../../../data/index';
import ctaContact from '../modules/ctaContact';

let elMainContent;
let elLangLearn;
let elWhoLearning;
let elWhyLearn;
let elGroup;
let elPriceContainer;
let elStudentReview1Show;
let elStudentReview1Hidden;
let elStudentReview2Show;
let elStudentReview2Hidden;
let elAboutMeWhatNext;

// top cta contact form
let elFormTopCtaContact;
let elFormTopCtaContactEmailAddress;
let elFormTopSubmitButton;
let elTopFormSubmitting;
let elTopFormNotSubmitted;
// we'll set this to true once the contact form is submitted, to prevent doubleclicks
let topFormSubmitted = false;

// main cta contact form
let elFormCtaContact;
let elFormCtaContactEmailAddress;
let elFormCtaContactLangLearn;
let elFormCtaContactSubmitButton;
let elFormSubmitting;
let elFormNotSubmitted;
// we'll set this to true once the contact form is submitted, to prevent doubleclicks
let contactFormSubmitted = false;

function publicInit() {
    _initVars();
    _initListeners();
    _initFunctionality();
}

function _initVars() {
    elMainContent = '[data-js="mainContent"]';
    elLangLearn = '[data-js="langLearn"]';
    elWhoLearning = '[data-js="whoLearning"]';
    elWhyLearn = '[data-js="whyLearn"]';
    elGroup = '[data-js="group"]';
    elPriceContainer = '[data-js="priceContainer"]';
    elStudentReview1Show = '[data-js="studentReview1Show"]';
    elStudentReview1Hidden = '[data-js="studentReview1Hidden"]';
    elStudentReview2Show = '[data-js="studentReview2Show"]';
    elStudentReview2Hidden = '[data-js="studentReview2Hidden"]';
    elAboutMeWhatNext = '[data-js="aboutMeWhatNext"]';

    // top cta contact form
    elFormTopCtaContact = '[data-js="formTopCtaContact"]';
    elFormTopCtaContactEmailAddress = '[data-js="formTopCtaContactEmailAddress"]';
    elFormTopSubmitButton = '[data-js="formTopSubmitButton"]';
    elTopFormSubmitting = '[data-js="topFormSubmitting"]';
    elTopFormNotSubmitted = '[data-js="topFormNotSubmitted"]';

    // main cta contact form
    elFormCtaContact = '[data-js="formCtaContact"]';
    elFormCtaContactEmailAddress = '[data-js="formCtaContactEmailAddress"]';
    elFormCtaContactLangLearn = '[data-js="formCtaContactLangLearn"]';
    elFormCtaContactSubmitButton = '[data-js="formCtaContactSubmitButton"]';
    elFormSubmitting = '[data-js="formSubmitting"]';
    elFormNotSubmitted = '[data-js="formNotSubmitted"]';
}

function _initFunctionality() {
    const currentLangLearnCookie = cookieManager.getLangLearnFrontend();
    if (currentLangLearnCookie !== false) {
        // show this lang in the dropdowns
        $(elFormCtaContactLangLearn).val(currentLangLearnCookie);
        $(elLangLearn).val(currentLangLearnCookie);

        // display price of this lang
        _displayPrice(currentLangLearnCookie);

        // then make it visible. we make it invisible on pageload so that changing the price
        // here doesn't cause it to "flicker"
        ui.makeVisible(elPriceContainer);

        // no cookie is set, we'll make the default price visible
    } else {
        ui.makeVisible(elPriceContainer);
    }

    const currentWhoLearningCookie = cookieManager.getWhoLearning();
    if (currentWhoLearningCookie !== false) {
        $(elWhoLearning).val(currentWhoLearningCookie);
    }

    const currentWhyLearnCookie = cookieManager.getWhyLearn();
    if (currentWhyLearnCookie !== false) {
        $(elWhyLearn).val(currentWhyLearnCookie);
    }

    const currentGroupCookie = cookieManager.getGroup();
    if (currentGroupCookie !== false) {
        $(elGroup).val(currentGroupCookie);
    }
}

function _initListeners() {
    _listenLangChange();
    _listenWhoLearningChange();
    _listenWhyLearnChange();
    _listenGroupChange();
    _listenStudentReviewOneReadMore();
    _listenStudentReviewTwoReadMore();

    // cta forms
    _listenFormTopSubmit();
    _listenMainFormSubmit();
    // change language on the main form
    _listenLangChangeCtaForm();
}

// changes to the top language dropdown
function _listenLangChange() {
    // changes to the top hero
    $(elMainContent).on('change', elLangLearn, (event) => {
        const stringLangId = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringLangId) {
            const langId = parseInt(stringLangId, 10);
            localSettings.language(langId);

            // attempt to set the value of the CTA dropdown
            $(elFormCtaContactLangLearn).val(langId);

            // display price of this lang
            _displayPrice(langId);

            // also check the other boxes, if they have values then show our "what next" box
            const whoLearning = $(elWhoLearning).val();
            const whyLearn = $(elWhyLearn).val();
            if (whoLearning && whyLearn) {
                ui.show(elAboutMeWhatNext);
            }
        }
    });
}

// changes to who learning dropdown
function _listenWhoLearningChange() {
    $(elMainContent).on('change', elWhoLearning, (event) => {
        const stringWhoLearning = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringWhoLearning) {
            const whoLearningVal = parseInt(stringWhoLearning, 10);
            localSettings.whoLearning(whoLearningVal);

            // also check the other boxes, if they have values then show our "what next" box
            const langLearn = $(elLangLearn).val();
            const whyLearn = $(elWhyLearn).val();
            if (langLearn && whyLearn) {
                ui.show(elAboutMeWhatNext);
            }
        }
    });
}

// changes to why learn dropdown
function _listenWhyLearnChange() {
    $(elMainContent).on('change', elWhyLearn, (event) => {
        const stringWhyLearn = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringWhyLearn) {
            const whyLearnVal = parseInt(stringWhyLearn, 10);
            localSettings.whyLearn(whyLearnVal);

            // also check the other boxes, if they have values then show our "what next" box
            const langLearn = $(elLangLearn).val();
            const whoLearning = $(elWhoLearning).val();
            if (langLearn && whoLearning) {
                ui.show(elAboutMeWhatNext);
            }
        }
    });
}

// changes to group
function _listenGroupChange() {
    $(elMainContent).on('change', elGroup, (event) => {
        const stringGroup = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringGroup) {
            const groupVal = parseInt(stringGroup, 10);
            localSettings.group(groupVal);
        }
    });
}

// after language has changed, show the new price of this language
function _displayPrice(langId) {
    let priceFound = false;
    let priceText;

    // find price
    siteData.prices.forEach((element) => {
        if (element.langIdFrontend === langId) {
            priceFound = true;
            priceText = element.price30Mins20Hr;
        }
    });

    if (priceFound === true) {
        $(elPriceContainer).html(priceText);
    }
}

// region cta

// top cta form
function _listenFormTopSubmit() {
    $(elMainContent).on('submit', elFormTopCtaContact, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        if (topFormSubmitted === false) {
            topFormSubmitted = true;

            // disable the button and show loading spinner
            ui.disable(elFormTopSubmitButton);
            ui.hide(elTopFormNotSubmitted);
            ui.show(elTopFormSubmitting);

            // the users email address
            const eml = $(elFormTopCtaContactEmailAddress).val();
            // this top form has no language selection
            const lang = null;

            // submit the form
            ctaContact.submitForm(event, eml, lang);
        }
    });
}

// main cta form
function _listenMainFormSubmit() {
    $(elMainContent).on('submit', elFormCtaContact, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        if (contactFormSubmitted === false) {
            contactFormSubmitted = true;

            // disable the button and show loading spinner
            ui.disable(elFormCtaContactSubmitButton);
            ui.hide(elFormNotSubmitted);
            ui.show(elFormSubmitting);

            // the users email address
            const eml = $(elFormCtaContactEmailAddress).val();
            // language selected
            const lang = $(elFormCtaContactLangLearn).val();

            // submit the form
            ctaContact.submitForm(event, eml, lang);
        }
    });
}

// main cta form, language change
function _listenLangChangeCtaForm() {
    // changes to the bottom cta
    $(elMainContent).on('change', elFormCtaContactLangLearn, (event) => {
        const stringLangId = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringLangId) {
            const langId = parseInt(stringLangId, 10);
            localSettings.language(langId);

            // attempt to set the value of the hero section dropdown
            $(elLangLearn).val(langId);

            // display price of this lang now that language has changed
            _displayPrice(langId);
        }
    });
}

// endregion

// region listen for "read more.." clicks on student reviews, show text once clicked
function _listenStudentReviewOneReadMore() {
    $(elMainContent).on('click', elStudentReview1Show, () => {
        ui.hide(elStudentReview1Show);
        ui.show(elStudentReview1Hidden);
    });
}

function _listenStudentReviewTwoReadMore() {
    $(elMainContent).on('click', elStudentReview2Show, () => {
        ui.hide(elStudentReview2Show);
        ui.show(elStudentReview2Hidden);
    });
}
// endregion

export default publicInit;

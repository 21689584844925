import ui from '../modules/ui';
import ctaContact from '../modules/ctaContact';

let elMainContent;

// top cta contact form
let elFormTopCtaContact;
let elFormTopCtaContactEmailAddress;
let elFormTopSubmitButton;
let elTopFormSubmitting;
let elTopFormNotSubmitted;
// we'll set this to true once the contact form is submitted, to prevent doubleclicks
let topFormSubmitted = false;

function publicInit() {
    _initVars();
    _initListeners();
    _initFunctionality();
}

function _initVars() {
    elMainContent = '[data-js="mainContent"]';

    // top cta contact form
    elFormTopCtaContact = '[data-js="formTopCtaContact"]';
    elFormTopCtaContactEmailAddress = '[data-js="formTopCtaContactEmailAddress"]';
    elFormTopSubmitButton = '[data-js="formTopSubmitButton"]';
    elTopFormSubmitting = '[data-js="topFormSubmitting"]';
    elTopFormNotSubmitted = '[data-js="topFormNotSubmitted"]';
}

function _initFunctionality() {
    // nothing yet
    console.log('loaded!');
}

function _initListeners() {
    _listenFormTopSubmit();
}

// top cta form
function _listenFormTopSubmit() {
    $(elMainContent).on('submit', elFormTopCtaContact, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        if (topFormSubmitted === false) {
            topFormSubmitted = true;

            // disable the button and show loading spinner
            ui.disable(elFormTopSubmitButton);
            ui.hide(elTopFormNotSubmitted);
            ui.show(elTopFormSubmitting);

            // the users email address
            const eml = $(elFormTopCtaContactEmailAddress).val();
            // this top form has no language selection
            const lang = null;

            // submit the form
            ctaContact.submitForm(event, eml, lang);
        }
    });
}

export default publicInit;

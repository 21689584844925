import localSettings from './localSettings';
import flrf from '../domain/flrf';
import cookieManager from './cookieManager';
import { siteData } from '../../../data/index';
import logErr from './logErr';
import tagManager from './tagManager';
import storageManager from './storageManager';

function submitTheForm(event, email, language) {
    // stop the default behavior of forms, which is to send the user to a new page
    event.preventDefault();

    // urls to forward the user to
    const fowardTo = `${siteData.url.pages.freetrial}?ref=contact`;
    const fowardToPast = `${siteData.url.pages.freetrial}?ref=contactPast`;
    const forwardToAlreadyHasAccount = `${siteData.url.pages.login}?ref=contact`;

    // data we'll send to ajax
    const dt = {
        email: null,
        langFrontend: null,
        whoLearning: null,
        whyLearn: null,
        group: null,
        utmData: null,
    };

    // get the email address, and set local settings. if email was empty, log that error and
    // forward the user to the sign up page
    if (!email) {
        logErr.msg('email address was empty from the cta form');
        window.location.href = siteData.url.pages.freetrial;
    }
    const emlTrimmed = email.trim();
    localSettings.email(emlTrimmed);
    dt.email = emlTrimmed;

    // get the language from the form, if it's set, and set local settings
    if (language) {
        const langId = parseInt(language, 10);
        localSettings.language(langId);
    }

    // region get the values of the other marketing cookies, if they exist
    const langIdCookie = cookieManager.getLangLearnFrontend();
    if (langIdCookie !== false) {
        dt.langFrontend = langIdCookie;
    }

    const whoLearning = cookieManager.getWhoLearning();
    if (whoLearning !== false) {
        dt.whoLearning = whoLearning;
    }
    const whyLearn = cookieManager.getWhyLearn();
    if (whyLearn !== false) {
        dt.whyLearn = whyLearn;
    }
    const group = cookieManager.getGroup();
    if (group !== false) {
        dt.group = group;
    }
    const utmData = storageManager.getUtmRecords();
    if (utmData !== false) {
        dt.utmData = utmData;
    }
    // endregion

    // ajax, possible outcomes
    //  1 new user. forward them to free trial page with query string
    //  2 user already has a real account
    //  3 user already in flrf form table
    //  4 some error occurred, e.g. email address was empty
    flrf.submit(dt)
        .then((data) => {
            // new user. forward them to free trial page
            if (data.outcome === 1) {
                // also fire our tag conversion event
                tagManager.convContactUs();

                // forward to the signup page
                window.location.href = fowardTo;

                // user already has an account. show a message
            } else if (data.outcome === 2) {
                window.location.href = forwardToAlreadyHasAccount;

                // user already in flrf form table
            } else if (data.outcome === 3) {
                window.location.href = fowardToPast;

                // an error occurred. we'll still forward them to our sign up page
            } else {
                window.location.href = fowardTo;
            }
        })
        .catch((e) => {
            // log the error, but still forward the user to our signup page
            logErr.err(e);
            window.location.href = fowardTo;
        });
}

const ctaContact = {
    submitForm: submitTheForm,
};

export default ctaContact;

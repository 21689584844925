import atatus from 'atatus-js';
import { siteData } from '../../../data/index';
import logErr from './logErr';

// initialize atatus, our performance monitor
function initialize() {
    atatus.config(
        siteData.envSpecificData.atatus.uid,
        { disableErrorTracking: true },
    ).install();
}

// given the integer our app uses to call perf mon, get the "friendly name"
function _getTransactionName(num) {
    let name;

    // get the name of the perfomance monitor. we send this to our perf mon software
    // 1: on the free trial signup page, how long it takes to get and display form fields
    if (num === 1) {
        name = 'getSignupFormFields';

        // failed to find the name of this perf mon, log this error
    } else {
        name = false;
        logErr.msg(`failed to find the perf mon transaction: ${num}`);
    }

    return name;
}

function startTransaction(num) {
    const name = _getTransactionName(num);
    if (name !== false) {
        window.atatus.beginTransaction(name);
    }
}

function endTransaction(num) {
    const name = _getTransactionName(num);
    if (name !== false) {
        window.atatus.endTransaction(name);
    }
}

function failTransaction(num) {
    const name = _getTransactionName(num);
    if (name !== false) {
        window.atatus.failTransaction(name);
    }
}

const perfMon = {
    init: initialize,
    start: startTransaction,
    end: endTransaction,
    fail: failTransaction,
};

export default perfMon;

// region page

// simple pageview. this should be called on every pageload
function page() {
    $(document).ready(() => {
        // window.analytics.ready(() => {
        //     // IMPORTANT NOTE: do NOT try to include user_id here. According to Segment docs
        //     //  "In our browser and mobile libraries a User ID is automatically added from the
        //     //  state stored by a previous identify call, so you do not need to add it yourself.
        //     //  They will also automatically handle Anonymous ID’s under the covers."
        //     window.analytics.page();
        // });

        // rudderstack
        window.rudderanalytics.ready(() => {
            window.rudderanalytics.page();
        });
    });
}

// endregion

// region track

// Contact Initiated
// the flrf "free lesson request form" we'll fire an event
function trackFlrfFormSubmission() {
    $(document).ready(() => {
        // window.analytics.ready(() => {
        //     window.analytics.track('Contact Initiated', {
        //         type: 'flrf',
        //     });
        // });

        // rudderstack
        window.rudderanalytics.ready(() => {
            window.rudderanalytics.track('Contact Initiated', {
                type: 'flrf',
            });
        });
    });
}

// Account Created
function trackAccountCreatedSubmission() {
    $(document).ready(() => {
        // window.analytics.ready(() => {
        //     // note that we don't include any identifying information, like user_id. segment
        //     // attaches that information automatically, as long as we've made an identify call
        //     // for this user in the past
        //     window.analytics.track('Account Created');
        // });

        // rudderstack
        window.rudderanalytics.ready(() => {
            window.rudderanalytics.track('Account Created');
        });
    });
}

// endregion

const tagSegment = {
    pageview: page,
    trackFlrfForm: trackFlrfFormSubmission,
    trackAccountCreated: trackAccountCreatedSubmission,
};

export default tagSegment;

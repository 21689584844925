import queryString from 'query-string';
import tippy from 'tippy.js';
import cookieManager from '../modules/cookieManager';
import localSettings from '../modules/localSettings';
import ui from '../modules/ui';
import ctaContact from '../modules/ctaContact';

let elMainContent;
let elLangLearn;
let elWhoLearning;
let elWhyLearn;
let elStudentReview1Show;
let elStudentReview1Hidden;

// top cta contact form
let elFormTopCtaContact;
let elFormTopCtaContactEmailAddress;
let elFormTopSubmitButton;
let elTopFormSubmitting;
let elTopFormNotSubmitted;
// we'll set this to true once the contact form is submitted, to prevent doubleclicks
let topFormSubmitted = false;

// main cta contact form
let elFormCtaContact;
let elFormCtaContactEmailAddress;
let elFormCtaContactLangLearn;
let elFormCtaContactSubmitButton;
let elFormSubmitting;
let elFormNotSubmitted;
// we'll set this to true once the contact form is submitted, to prevent doubleclicks
let contactFormSubmitted = false;

// tooltip for scroll to learn more
let tooltipScrollLearnMore;
// we'll set this to true when showing/hiding tooltip is in process
let tooltipDisplayInProcess = false;

function publicInit() {
    _initVars();
    _initListeners();
    _initFunctionality();
}

function _initVars() {
    elMainContent = '[data-js="mainContent"]';
    elLangLearn = '[data-js="langLearn"]';
    elWhoLearning = '[data-js="whoLearning"]';
    elWhyLearn = '[data-js="whyLearn"]';
    elStudentReview1Show = '[data-js="studentReview1Show"]';
    elStudentReview1Hidden = '[data-js="studentReview1Hidden"]';

    // top cta contact form
    elFormTopCtaContact = '[data-js="formTopCtaContact"]';
    elFormTopCtaContactEmailAddress = '[data-js="formTopCtaContactEmailAddress"]';
    elFormTopSubmitButton = '[data-js="formTopSubmitButton"]';
    elTopFormSubmitting = '[data-js="topFormSubmitting"]';
    elTopFormNotSubmitted = '[data-js="topFormNotSubmitted"]';

    // main cta contact form
    elFormCtaContact = '[data-js="formCtaContact"]';
    elFormCtaContactEmailAddress = '[data-js="formCtaContactEmailAddress"]';
    elFormCtaContactLangLearn = '[data-js="formCtaContactLangLearn"]';
    elFormCtaContactSubmitButton = '[data-js="formCtaContactSubmitButton"]';
    elFormSubmitting = '[data-js="formSubmitting"]';
    elFormNotSubmitted = '[data-js="formNotSubmitted"]';
}

function _initFunctionality() {
    // region set the language dropdown, from either cookie or url param

    // get the url params
    const parsed = queryString.parse(window.location.search);
    const langFromUrl = parsed.lang;

    // get the cookie
    const currentLangLearnCookie = cookieManager.getLangLearnFrontend();

    // if lang exists in the url, use it. and set cookie value
    if (langFromUrl) {
        const langIdFromUrl = parseInt(langFromUrl, 10);

        // set the dropdown, and also set language id cookie
        $(elLangLearn).val(langFromUrl);
        localSettings.language(langIdFromUrl);

        // else if lang exists in cookies, use that
    } else if (currentLangLearnCookie !== false) {
        $(elLangLearn).val(currentLangLearnCookie);
        $(elFormCtaContactLangLearn).val(currentLangLearnCookie);
    }

    // endregion

    const currentWhoLearningCookie = cookieManager.getWhoLearning();
    if (currentWhoLearningCookie !== false) {
        $(elWhoLearning).val(currentWhoLearningCookie);
    }

    const currentWhyLearnCookie = cookieManager.getWhyLearn();
    if (currentWhyLearnCookie !== false) {
        $(elWhyLearn).val(currentWhyLearnCookie);
    }

    // this is the element the tooltip will be "attached" to
    const tooltipAttachmentElement = document.querySelector(elWhyLearn);

    // the html template/content
    const tDoc = document.getElementById('tooltipTemplateScrollToLearnMore');

    // tippy options
    const tooltipSaveTippyOptions = {
        content: tDoc.innerHTML,
        allowHTML: true,
        // trigger should always be manual when trigging a tooltip via js
        trigger: 'manual',
        // tooltip entrance animation duration
        duration: 1000,
        // our custom theme
        theme: 'white',
        zIndex: 1,
        // arrow, don't show
        arrow: false,
        // tooltip will show below the attached element
        placement: 'bottom',
        // never hide upon clicking, we'll hide it after x seconds
        hideOnClick: false,
    };

    // and finally initialize the tooltip. this does not show it, that happens later
    tooltipScrollLearnMore = tippy(tooltipAttachmentElement, tooltipSaveTippyOptions);
}

function _initListeners() {
    _listenLangChange();
    _listenLangChangeCtaForm();
    _listenWhoLearningChange();
    _listenWhyLearnChange();
    _listenStudentReviewOneReadMore();

    // cta forms
    _listenFormTopSubmit();
    _listenMainFormSubmit();
}

// changes to the top language dropdown
function _listenLangChange() {
    $(elMainContent).on('change', elLangLearn, (event) => {
        const stringLangId = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringLangId) {
            const langId = parseInt(stringLangId, 10);
            localSettings.language(langId);

            // attempt to set the value of the CTA dropdown
            $(elFormCtaContactLangLearn).val(langId);

            // also check the other boxes, if they have values then show our "learn more" tooltip
            const whoLearning = $(elWhoLearning).val();
            const whyLearn = $(elWhyLearn).val();
            if (whoLearning && whyLearn) {
                _showThenHideTooltip();
            }
        }
    });
}

// language changes to the main cta form
function _listenLangChangeCtaForm() {
    // changes to the bottom cta
    $(elMainContent).on('change', elFormCtaContactLangLearn, (event) => {
        const stringLangId = $(event.currentTarget).val();

        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringLangId) {
            const langId = parseInt(stringLangId, 10);
            localSettings.language(langId);

            // attempt to set the value of the top section dropdown
            $(elLangLearn).val(langId);
        }
    });
}

// changes to who learning dropdown
function _listenWhoLearningChange() {
    $(elMainContent).on('change', elWhoLearning, (event) => {
        const stringWhoLearning = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringWhoLearning) {
            const whoLearningVal = parseInt(stringWhoLearning, 10);
            localSettings.whoLearning(whoLearningVal);

            // also check the other boxes, if they have values then show our "learn more" tooltip
            const langLearn = $(elLangLearn).val();
            const whyLearn = $(elWhyLearn).val();
            if (langLearn && whyLearn) {
                _showThenHideTooltip();
            }
        }
    });
}

// changes to why learn dropdown
function _listenWhyLearnChange() {
    $(elMainContent).on('change', elWhyLearn, (event) => {
        const stringWhyLearn = $(event.currentTarget).val();
        // convert to an int, if there is a value. no value means user selected the empty option
        if (stringWhyLearn) {
            const whyLearnVal = parseInt(stringWhyLearn, 10);
            localSettings.whyLearn(whyLearnVal);

            // also check the other boxes, if they have values then show our "what next" box
            const langLearn = $(elLangLearn).val();
            const whoLearning = $(elWhoLearning).val();
            if (langLearn && whoLearning) {
                _showThenHideTooltip();
            }
        }
    });
}

function _showThenHideTooltip() {
    if (tooltipDisplayInProcess === false) {
        tooltipScrollLearnMore.show();
        window.setTimeout(
            () => {
                tooltipScrollLearnMore.hide();
                tooltipDisplayInProcess = false;
            },
            3000,
        );
    }
}

// region cta forms

// top cta form
function _listenFormTopSubmit() {
    $(elMainContent).on('submit', elFormTopCtaContact, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        if (topFormSubmitted === false) {
            topFormSubmitted = true;

            // disable the button and show loading spinner
            ui.disable(elFormTopSubmitButton);
            ui.hide(elTopFormNotSubmitted);
            ui.show(elTopFormSubmitting);

            // the users email address
            const eml = $(elFormTopCtaContactEmailAddress).val();
            // this top form has no language selection
            const lang = null;

            // submit the form
            ctaContact.submitForm(event, eml, lang);
        }
    });
}

// main cta form
function _listenMainFormSubmit() {
    $(elMainContent).on('submit', elFormCtaContact, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        if (contactFormSubmitted === false) {
            contactFormSubmitted = true;

            // disable the button and show loading spinner
            ui.disable(elFormCtaContactSubmitButton);
            ui.hide(elFormNotSubmitted);
            ui.show(elFormSubmitting);

            // the users email address
            const eml = $(elFormCtaContactEmailAddress).val();
            // language selected
            const lang = $(elFormCtaContactLangLearn).val();

            // submit the form
            ctaContact.submitForm(event, eml, lang);
        }
    });
}

// endregion

// region listen for "read more.." clicks on student reviews, show text once clicked
function _listenStudentReviewOneReadMore() {
    $(elMainContent).on('click', elStudentReview1Show, () => {
        ui.hide(elStudentReview1Show);
        ui.show(elStudentReview1Hidden);
    });
}

// endregion

export default publicInit;

import rnd from 'simple-random/browser';

// generate random number string using simple random. if no integer length is passed, default
// is 24. this will only generate numbers, upper, and lowercase letters (so safe for UIDs)
function generateRandomString(strlen = 24) {
    let randomString;

    // if the user's browser supports crypto, use that. else use Math.random
    if (rnd.isSecureSupported) {
        randomString = rnd({
            length: strlen,
            secure: true,
        });
    } else {
        randomString = rnd({
            length: strlen,
        });
    }

    // if our random string length was what we asked for
    if (randomString.length === strlen) {
        return randomString;
    }

    throw new Error('rand.randuid failed to generate a uid of correct length');
}

const rand = {
    randuid: generateRandomString,
};

export default rand;

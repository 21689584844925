import { siteData } from '../../../data/index';

// our main api key for facebook
const facebookPixelId = siteData.envSpecificData.facebook.pixelId;

// todo IMPORTANT if you edit this, application function also needs to be edited. you can
//  directly copy/paste this entire function
// given our standard tag data from cookies, convert it to what is needed for facebook api calls
function _buildTagDataForFacebook(tagData) {
    // region data that is used in facebook init
    // the data passed in init can't be used to create custom audiences. instead, it is used by
    // fb to help better identify users from our site on fb
    let haveAnyInitData = false;
    const initData = {};

    // if we have the user's uid. uid is not used for matching users on facebook, it's used
    // for better keeping track of users across devices
    if (tagData.haveTgI === true) {
        initData.uid = tagData.tgI;
        haveAnyInitData = true;
    }

    // if we have email, add that
    if (tagData.haveTgEml === true) {
        initData.em = tagData.tgEml;
        haveAnyInitData = true;
    }

    // if we have first name, add that
    if (tagData.haveTgFnm === true) {
        initData.fn = tagData.tgFnm;
        haveAnyInitData = true;
    }

    // if we have last name, add that
    if (tagData.haveTgLnm === true) {
        initData.ln = tagData.tgLnm;
        haveAnyInitData = true;
    }

    // if the visitor is a career applicant. this is a boolean
    // todo we're always going to send this 'career applicant' data to fb, so we can remove
    //  this haveAnyInitData var
    haveAnyInitData = true;
    initData.isCareer = tagData.tgIsCareer;

    // endregion

    // region "extra" data, used outside of fbq init call

    let haveAnyExtraData = false;
    const extraDt = {};

    // we'll set this to true if we know for sure the user is a teacher or an admin. it will be
    // false if we know for sure the user is a student/customer, or if we don't know who the user
    // is (may be the case on the login page, if the user has no category cookie)
    let userIsTeacherOrAdmin = false;
    // if we have category
    if (tagData.haveTgCt === true) {
        if (tagData.tgCt === 100) {
            haveAnyExtraData = true;
            extraDt.hasAccount = 1;
            extraDt.userType = 1;
        } else if (tagData.tgCt === 101) {
            haveAnyExtraData = true;
            extraDt.hasAccount = 1;
            extraDt.hasTrialScheduled = 1;
            extraDt.userType = 1;
        } else if (tagData.tgCt === 102) {
            haveAnyExtraData = true;
            extraDt.hasAccount = 1;
            extraDt.hasTrialScheduled = 1;
            extraDt.hasTrialCompleted = 1;
            extraDt.userType = 1;
        } else if (tagData.tgCt === 103) {
            haveAnyExtraData = true;
            extraDt.hasAccount = 1;
            extraDt.hasTrialScheduled = 1;
            extraDt.hasTrialCompleted = 1;
            extraDt.hasPurchased = 1;
            extraDt.userType = 1;

            // user is a teacher or admin
        } else if (tagData.tgCt === 200) {
            haveAnyExtraData = true;
            extraDt.userType = 2;
            userIsTeacherOrAdmin = true;
        }
    }

    // if the user is not a teacher/admin and
    // if we have the language the user is interested in, pass that
    if (userIsTeacherOrAdmin === false && tagData.haveLang === true) {
        extraDt.langLearn = tagData.tgLang;
        haveAnyExtraData = true;
    }
    // endregion

    // build and return data
    const allData = {
        haveAnyInitData,
        initData,
        haveAnyExtraData,
        extraDt,
    };
    return allData;
}

// todo IMPORTANT if you edit this, application function also needs to be edited. you can
//  directly copy/paste this entire function
// facebook init and page view
function facebookInitializeAndPageview(tagData) {
    /* eslint-disable */
    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    /* eslint-enable */

    // get the tag data into the format we want for sending to fb
    const allDataFbFormat = _buildTagDataForFacebook(tagData);

    // if we have any user related date, we can pass that in the init function. this helps fb
    // match a user from our site to a person on fb
    if (allDataFbFormat.haveAnyInitData === true) {
        window.fbq('init', facebookPixelId, allDataFbFormat.initData);

        // but if we don't have any user data, we'll have to call init plainly
    } else {
        window.fbq('init', facebookPixelId);
    }

    // general page view
    window.fbq('track', 'PageView');

    // finally, pass any extra data we have collected, in our special data event
    _dataConveyor(allDataFbFormat);
}

// todo IMPORTANT if you edit this, application function also needs to be edited. you can
//  directly copy/paste this entire function
// our custom fb event which sends user data. the name of the event is "eventDataConveyor"
// this external function takes in our normal tag data format
function eventDataConveyor(tagData) {
    const allDataFbFormat = _buildTagDataForFacebook(tagData);
    _dataConveyor(allDataFbFormat);
}

// todo IMPORTANT if you edit this, application function also needs to be edited. you can
//  directly copy/paste this entire function
// this internal function requires data in our fb format
function _dataConveyor(allDataFbFormat) {
    if (allDataFbFormat.haveAnyExtraData === true) {
        window.fbq(
            'trackCustom',
            'dataConveyor',
            allDataFbFormat.extraDt,
        );
    }
}

// contact us event
function eventContactUs() {
    window.fbq(
        'trackCustom',
        'convCtaContactUs',
    );
}

// user account created
function eventCompleteRegistration() {
    window.fbq(
        'track',
        'CompleteRegistration',
    );
}

// export
const tagFacebook = {
    initAndPageview: facebookInitializeAndPageview,
    dataConveyor: eventDataConveyor,
    contactUs: eventContactUs,
    completeRegistration: eventCompleteRegistration,
};

export default tagFacebook;

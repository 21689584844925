// todo VERY IMPORTANT: the frontend ids are used on the freetrial signup page to send server
//  data about langspecific questions for spanish and portuguese..which "subtype" they are studying
//  so don't change them unless you also update the code there

// todo IMPORTANT: if you edit this, you also need to edit the file in /application
const languages = [
    {
        frontendId: 1,
        backendId: 1,
        nameEnglish: 'Spanish - Latin American',
    },
    {
        frontendId: 2,
        backendId: 1,
        nameEnglish: 'Spanish - European',
    },
    {
        frontendId: 3,
        backendId: 4,
        nameEnglish: 'French',
    },
    {
        frontendId: 4,
        backendId: 9,
        nameEnglish: 'German',
    },
    {
        frontendId: 5,
        backendId: 3,
        nameEnglish: 'Italian',
    },
    {
        frontendId: 6,
        backendId: 7,
        nameEnglish: 'Japanese',
    },
    {
        frontendId: 7,
        backendId: 10,
        nameEnglish: 'Mandarin Chinese',
    },
    {
        frontendId: 8,
        backendId: 5,
        nameEnglish: 'Portuguese - Brazilian',
    },
    {
        frontendId: 9,
        backendId: 5,
        nameEnglish: 'Portuguese - European',
    },
    {
        frontendId: 10,
        backendId: 11,
        nameEnglish: 'Arabic',
    },
    {
        frontendId: 11,
        backendId: 6,
        nameEnglish: 'Russian',
    },
    {
        frontendId: 12,
        backendId: 8,
        nameEnglish: 'Latin',
    },
];

module.exports = languages;

import cookieManager from './cookieManager';
import storageManager from './storageManager';
import tagGooogleAnalytics from './tagGoogleAnalytics';
import tagFacebook from './tagFacebook';
import validations from './validations';
import languages from './languages';
import logErr from './logErr';
import tagSegment from './tagSegment';

// todo IMPORTANT if you edit this, application function also needs to be edited. you **cannot**
//  simply copy paste this. getting lang cookie value is different here. also, in the app
//  we don't have a tgIsCareer for career applicants
// attempt to get tag data from cookies and storage
function getTagDataFromCookies() {
    // main tag data obj we will pass to our tag manager. each tag value has a bool,
    // e.g. "haveTgI" that tells us whether or not we have this data
    // tgDataUpdateNeeded is a bool that tells us whether or not we need to make an ajax/server
    // call to get updated data. this will be true if we are missing any tag data or our data
    // is old
    const tagData = {
        tgDataUpdateNeeded: false,
        tgI: false,
        haveTgI: false,
        tgFnm: false,
        haveTgFnm: false,
        tgLnm: false,
        haveTgLnm: false,
        tgEml: false,
        haveTgEml: false,
        tgCt: false,
        haveTgCt: false,
        tgLang: false,
        haveLang: false,
        tgIsCareer: false,
    };

    // get tgI, the user's random id. basic validate is that is length
    const uidCookie = cookieManager.getTgI();
    if (uidCookie !== false && uidCookie.length > 8) {
        tagData.haveTgI = true;
        tagData.tgI = uidCookie;
    }

    // get tgFnm, the user's first name. basic validate is that is length
    const firstnameCookie = cookieManager.getTgFnm();
    if (firstnameCookie !== false && firstnameCookie.length > 0) {
        tagData.haveTgFnm = true;
        tagData.tgFnm = firstnameCookie;
    }

    // get tgLnm, the user's last name. basic validate is that is length
    const lastnameCookie = cookieManager.getTgLnm();
    if (lastnameCookie !== false && lastnameCookie.length > 0) {
        tagData.haveTgLnm = true;
        tagData.tgLnm = lastnameCookie;
    }

    // get the email, from marketing email cookie. basic validate is that is length
    const emailCookie = cookieManager.getMarketingEmail();
    if (emailCookie !== false && emailCookie.length > 2) {
        tagData.haveTgEml = true;
        tagData.tgEml = emailCookie;
    }

    // get the category (tgCt). also validate it to ensure it's a valid value
    const ctCookie = cookieManager.getTgCt();
    if (ctCookie !== false) {
        const isUserCategoryValid = validations.tagUserCategory(ctCookie);
        if (isUserCategoryValid === true) {
            tagData.tgCt = ctCookie;
            tagData.haveTgCt = true;
        }
    }

    // get langlearn, if it exists. also validate it to ensure it's a valid value
    const langlearnFrontendCookie = cookieManager.getLangLearnFrontend();
    const langlearnBackendCookie = cookieManager.getLangLearnBackend();
    if (langlearnFrontendCookie !== false || langlearnBackendCookie !== false) {
        let langIdToSend;
        // if we have backend langlearn, use that, bc no conversion is needed
        if (langlearnBackendCookie !== false) {
            langIdToSend = langlearnBackendCookie;

            // else use the frontend langlearn cookie. but we only pass backend lang ids to our
            // tags, so get the backend id
        } else {
            const langDetails = languages.frontendLanguageIdConvert(langlearnFrontendCookie);
            if (langDetails.success === true) {
                langIdToSend = langDetails.backendId;
            }
        }
        const isLangIdValid = validations.languageId(langIdToSend);
        if (isLangIdValid === true) {
            tagData.tgLang = langIdToSend;
            tagData.haveLang = true;
        }
    }

    // on breezy, we set a cookie isCareerApplicant = 1. so get that, and set career localstorage
    // if it exists. we do this just because we want all tag data to be in localstorage, but we
    // can't set localstorage on breezy.hr
    cookieManager.getCareerSetLocal();
    // get tgIsCareer from localStorage. we ensure it is a boolean, and log if it is not
    const isCareerApplicant = storageManager.getIsCareerApplicant();
    if (isCareerApplicant === true) {
        tagData.tgIsCareer = true;
    } else if (isCareerApplicant === false) {
        tagData.tgIsCareer = false;
    } else {
        tagData.tgIsCareer = false;
        logErr.msg(`very bad, getIsCareerApplicant returned nonboolean: ${isCareerApplicant}`);
    }

    // get the timestamp our data was last updated. if it is more than 24 hours ago, we'll want
    // to make an ajax call to get updated data
    const lastUpdated = cookieManager.getTgLtUp();
    const allowedTimestamp = Date.now() - (24 * 60 * 60 * 1000);

    // if we are missing any tag data, we want to make an ajax/server call to get it. this bool
    // set to true tells our app that is the case
    // or if our last updated cookie tells us it has been too long since we last updated our data
    // note that we don't require langLearn cookie/tag data; a user can log in and not have that
    // info set yet, so we don't want to force it to exist otherwise we'd end up calling ajax
    // every time a page loads
    if (tagData.haveTgI !== true
        || tagData.haveTgFnm !== true
        || tagData.haveTgLnm !== true
        || tagData.haveTgEml !== true
        || tagData.haveTgCt !== true
        || (lastUpdated === false || lastUpdated < allowedTimestamp)) {
        tagData.tgDataUpdateNeeded = true;
    }

    return tagData;
}

// this should be called on every page load on our website
function initializeAndPageView(tagData) {
    tagSegment.pageview();
    tagGooogleAnalytics.initAndPageview(tagData);
    tagFacebook.initAndPageview(tagData);

    // this checks if any conversion events have occurred (by checking session store) and if so,
    // fires api calls to all our tags
    checkConversionEventStorageAndMakeApiCalls();
}

// check if conversion events have occurred, and if so fire apis
function checkConversionEventStorageAndMakeApiCalls() {
    // region account created
    // conversion event occurred. really this shouldn't happen in marketing area, but it is
    // possible, if user navigated backward immediately after creating account
    const didAccountCreatedEventOccur = storageManager.getConversionAccountCreated();
    if (didAccountCreatedEventOccur === true) {
        // delete session storage
        storageManager.removeConversionAccountCreated();
        // make api calls
        _conversionAccountCreatedExecute();
    }
    // endregion

    // region contact us

    const didContactUsEventOccur = storageManager.getConversionContactUs();
    if (didContactUsEventOccur === true) {
        // delete session storage
        storageManager.removeConversionContactUs();
        // make api calls
        _conversionContactUsExecute();
    }

    // endregion
}

// region conversion contact us

// set this event in sessionstore; we can't call apis directly becuase user is redirected
// immediately after contact us form is filled out
function conversionContactUsStore() {
    storageManager.setConversionContactUs();
}

// execute the api calls
function _conversionContactUsExecute() {
    return new Promise((resolve) => {
        try {
            tagSegment.trackFlrfForm();
            tagFacebook.contactUs();
            tagGooogleAnalytics.contactUs();
        } catch (e) {
            logErr.err(e);
        }
        resolve();
    });
}

// endregion

// region conversion account created

// set this event in sessionstore; we can't call apis directly becuase user is redirected
// immediately after creating an account
function conversionAccountCreatedStore() {
    storageManager.setConversionAccountCreated();
}

// execute the api calls
function _conversionAccountCreatedExecute() {
    return new Promise((resolve) => {
        try {
            tagSegment.trackAccountCreated();
            tagFacebook.completeRegistration();
            tagGooogleAnalytics.accountCreated();
        } catch (e) {
            logErr.err(e);
        }
        resolve();
    });
}

// endregion

// export
const tagManager = {
    getTgDataFromCookies: getTagDataFromCookies,
    initAndPageView: initializeAndPageView,
    convContactUs: conversionContactUsStore,
    convAccountCreated: conversionAccountCreatedStore,
};
export default tagManager;

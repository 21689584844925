import logErr from './logErr';

const conversionAccountCreated = 'conversionAccountCreated';
const conversionContactUs = 'conversionContactUs';
const isCareerApplicant = 'langconvo.isCareerApplicant';
const utmRecords = 'langconvo.utmRecords';

// region session storage

function _setSession(key, value) {
    const valueJson = JSON.stringify(value);
    window.sessionStorage.setItem(key, valueJson);
}

function _getSession(key) {
    const val = window.sessionStorage.getItem(key);
    const valueJson = JSON.parse(val);
    return valueJson;
}

function _removeSession(key) {
    window.sessionStorage.removeItem(key);
}

// endregion

// region local storage

function _setLocal(key, value) {
    const valueJson = JSON.stringify(value);
    window.localStorage.setItem(key, valueJson);
}

function _getLocal(key) {
    const returnVars = {};
    returnVars.keyExists = false;
    returnVars.isValid = false;
    returnVars.data = null;

    const val = window.localStorage.getItem(key);

    // if the key exists, attempt to parse its value
    if (val) {
        returnVars.keyExists = true;

        // if the value is invalid json, an error will be thrown. in that case we log the err, as
        // that indicates either our code errored while saving the value, or more unlikely, the
        // user edited the value
        try {
            returnVars.data = JSON.parse(val);
            returnVars.isValid = true;
        } catch (e) {
            logErr.err(e);
        }
    }

    return returnVars;
}

// endregion

// region conversionAccountCreated

// returns true if session store tells us account created event occurred. false otherwise
function getConversionAccountCreatedSession() {
    let doesexistConversionAccountCreated = false;
    const val = _getSession(conversionAccountCreated);
    if (val === true) {
        doesexistConversionAccountCreated = true;
    }
    return doesexistConversionAccountCreated;
}

// sets conversionAccountCreated to true, indicating account created conversion event occurred
function setConversionAccountCreatedSession() {
    _setSession(conversionAccountCreated, true);
}

function removeConversionAccountCreatedSession() {
    _removeSession(conversionAccountCreated);
}

// endregion

// region conversionContactUs

function getConversionContactUsSession() {
    let doesexistConversionContactUs = false;
    const val = _getSession(conversionContactUs);
    if (val === true) {
        doesexistConversionContactUs = true;
    }
    return doesexistConversionContactUs;
}

// sets conversionAccountCreated to true, indicating account created conversion event occurred
function setConversionContactUsSession() {
    _setSession(conversionContactUs, true);
}

function removeConversionContactUsSession() {
    _removeSession(conversionContactUs);
}

// endregion

// region isCareerApplicant

function setIsCareerApplicantLocal() {
    _setLocal(isCareerApplicant, true);
}

function getIsCareerApplicantLocal() {
    let doesExist = false;
    const val = _getLocal(isCareerApplicant);
    if (val.data === true) {
        doesExist = true;
    }
    return doesExist;
}

// endregion

// region utmRecords

function setUtmRecordsLocal(utmRecordJson) {
    _setLocal(utmRecords, utmRecordJson);
}

// this will return false if there is no existing utm data or the existing utm data is not properly
// formatted in an array. Otherwise it returns an array of the utm data
function getUtmRecordsLocal() {
    let returnData = false;
    const utmRecordsData = _getLocal(utmRecords);

    // utm record exists in local storage and is valid json and currently stored utm record data
    // is in array format only then append new utm record to old utmrecords array
    // Note: valid json doesn't necessarily means that it is also in array format hence extra check
    if (utmRecordsData.isValid === true && utmRecordsData.data.constructor === Array) {
        returnData = utmRecordsData.data;
    }

    return returnData;
}

// endregion

const storageManager = {
    getConversionAccountCreated: getConversionAccountCreatedSession,
    setConversionAccountCreated: setConversionAccountCreatedSession,
    removeConversionAccountCreated: removeConversionAccountCreatedSession,
    getConversionContactUs: getConversionContactUsSession,
    setConversionContactUs: setConversionContactUsSession,
    removeConversionContactUs: removeConversionContactUsSession,
    setIsCareerApplicant: setIsCareerApplicantLocal,
    getIsCareerApplicant: getIsCareerApplicantLocal,
    setUtmRecords: setUtmRecordsLocal,
    getUtmRecords: getUtmRecordsLocal,
};

export default storageManager;

import Cookie from 'js-cookie';
import storageManager from './storageManager';
import { siteData } from '../../../data/index';

// most cookies are long-lived, so lets make default expiration 3 years
const defaultExpires = 365 * 3;

// the name of each of our cookies
const cookieEmail = 'mktEmail';
const cookieLangLearnBackend = 'langlearn';
const cookieLangLearnFrontend = 'mktLangLearn';
const cookieWhoLearning = 'mktWhoLearning';
const cookieWhyLearn = 'mktWhyLearn';
const cookieGroup = 'mktGroup';
const cookieBackendEmail = 'email';
const cookieTagI = 'tgI';
const cookieTagFnm = 'tgFnm';
const cookieTagLnm = 'tgLnm';
const cookieTagLtUp = 'tgLtUp';
const cookieTagCt = 'tgCt';
const cookieCareerApplicant = 'isCareerApplicant';

/**
 * General method for setting a cookie
 */
function _setCookie(name, value, options = {}) {
    // options object is empty set expire to default expire value
    const expire = options.expire || defaultExpires;

    Cookie.set(name, value, {
        expires: expire,
        secure: true,
        domain: `.${siteData.envSpecificData.baseUrlName.url}.com`,
    });
}

function _getCookie(name) {
    const x = Cookie.get(name);

    // return false if the cookie does not exist
    if (!x) {
        return false;
    }

    return x;
}

// region email

function setCookieEmail(value) {
    // expiration for our tags lets make 5 years
    const options = {
        expire: 365 * 5,
    };
    const emailVal = value.trim();
    _setCookie(cookieEmail, emailVal, options);
}

function getCookieEmail() {
    let emailVal = _getCookie(cookieEmail);
    if (emailVal !== false) {
        emailVal = emailVal.trim();
    }
    return emailVal;
}

// endregion

// region langLearn

function setCookieLangLearnBackend(value) {
    _setCookie(cookieLangLearnBackend, value);
}

// get backend langLearn cookie as an integer
function getCookieLangLearnBackend() {
    const stringVal = _getCookie(cookieLangLearnBackend);
    if (stringVal !== false) {
        const intVal = parseInt(stringVal, 10);
        return intVal;
    }

    return stringVal;
}

// endregion

// region langLearnFrontend

function setCookieLangLearnFrontend(value) {
    _setCookie(cookieLangLearnFrontend, value);
}

// this cookie value should be an integer, so we cast to that if it exists
function getCookieLangLearnFrontend() {
    const stringVal = _getCookie(cookieLangLearnFrontend);
    if (stringVal !== false) {
        const intVal = parseInt(stringVal, 10);
        return intVal;
    }

    return stringVal;
}

// endregion

// region whoLearning
function setCookieWhoLearning(value) {
    _setCookie(cookieWhoLearning, value);
}

// this cookie value should be an integer, so we cast to that if it exists
function getCookieWhoLearning() {
    const stringVal = _getCookie(cookieWhoLearning);
    if (stringVal !== false) {
        const intVal = parseInt(stringVal, 10);
        return intVal;
    }

    return stringVal;
}
// endregion

// region whyLearn
function setCookieWhyLearn(value) {
    _setCookie(cookieWhyLearn, value);
}

// this cookie value should be an integer, so we cast to that if it exists
function getCookieWhyLearn() {
    const stringVal = _getCookie(cookieWhyLearn);
    if (stringVal !== false) {
        const intVal = parseInt(stringVal, 10);
        return intVal;
    }

    return stringVal;
}
// endregion

// region group
function setCookieGroup(value) {
    _setCookie(cookieGroup, value);
}

// this cookie value should be an integer, so we cast to that if it exists
function getCookieGroup() {
    const stringVal = _getCookie(cookieGroup);
    if (stringVal !== false) {
        const intVal = parseInt(stringVal, 10);
        return intVal;
    }

    return stringVal;
}
// endregion

// region backendemail. this is the cookie named exactly `email`, that is used for login

function setCookieBackendemail(value) {
    const emailVal = value.trim();
    _setCookie(cookieBackendEmail, emailVal);
}

function getCookieBackendemail() {
    let emailVal = _getCookie(cookieBackendEmail);
    if (emailVal !== false) {
        emailVal = emailVal.trim();
    }
    return emailVal;
}

// endregion

// region tgI

// this cookie is for the user's random id

function setTgICookie(id) {
    _setCookie(cookieTagI, id);
}

function getTgICookie() {
    return _getCookie(cookieTagI);
}

// endregion

// region tagFnm

// this cookie is for the user's first name

function setTgFnmCookie(fn) {
    // expiration for our tags lets make 5 years
    const options = {
        expire: 365 * 5,
    };
    _setCookie(cookieTagFnm, fn, options);
}

function getTgFnmCookie() {
    return _getCookie(cookieTagFnm);
}

// endregion

// region tagLnm

// this cookie is for the user's last name

function setTgLnmCookie(ln) {
    // expiration for our tags lets make 5 years
    const options = {
        expire: 365 * 5,
    };
    _setCookie(cookieTagLnm, ln, options);
}

function getTgLnmCookie() {
    return _getCookie(cookieTagLnm);
}

// endregion

// region tagLtUp

// this is the timestamp that we last updated our tag cookies. we'll use it to know if we need
// to make ajax call to get new tag values

function setTgLtUpCookie(timestamp) {
    const ts = parseInt(timestamp, 10);
    _setCookie(cookieTagLtUp, ts);
}

function getTgLtUpCookie() {
    const cookieValue = _getCookie(cookieTagLtUp);
    let formattedValue;
    if (cookieValue !== false) {
        formattedValue = parseInt(cookieValue, 10);
    } else {
        formattedValue = cookieValue;
    }
    return formattedValue;
}

// endregion

// region tagCt

function setTgCtCookie(category) {
    const cat = parseInt(category, 10);
    _setCookie(cookieTagCt, cat);
}

function getTgCtCookie() {
    const cookieValue = _getCookie(cookieTagCt);
    let formattedValue;
    if (cookieValue !== false) {
        formattedValue = parseInt(cookieValue, 10);
    } else {
        formattedValue = cookieValue;
    }
    return formattedValue;
}

// endregion

// region getCareer

// here, we get the career applicant cookie that breezy sets. if it exists, and is = 1, then
// we set our localstorage career applicant value. we do this just because we want to have
// all tag data in localstorage, but cannot do that on breezy.hr website
function getCareerCookieSetLocalStorage() {
    const cookieValue = _getCookie(cookieCareerApplicant);
    let formattedValue;
    if (cookieValue !== false) {
        formattedValue = parseInt(cookieValue, 10);
    } else {
        formattedValue = cookieValue;
    }

    // if this user is a career applicant, set localstorage
    if (formattedValue === 1) {
        storageManager.setIsCareerApplicant();
    }
}

// endregion

const cookieManager = {
    setMarketingEmail: setCookieEmail,
    getMarketingEmail: getCookieEmail,
    setLangLearnBackend: setCookieLangLearnBackend,
    getLangLearnBackend: getCookieLangLearnBackend,
    setLangLearnFrontend: setCookieLangLearnFrontend,
    getLangLearnFrontend: getCookieLangLearnFrontend,
    setWhoLearning: setCookieWhoLearning,
    getWhoLearning: getCookieWhoLearning,
    setWhyLearn: setCookieWhyLearn,
    getWhyLearn: getCookieWhyLearn,
    setGroup: setCookieGroup,
    getGroup: getCookieGroup,
    setBackendemail: setCookieBackendemail,
    getBackendemail: getCookieBackendemail,
    setTgI: setTgICookie,
    getTgI: getTgICookie,
    setTgFnm: setTgFnmCookie,
    getTgFnm: getTgFnmCookie,
    setTgLnm: setTgLnmCookie,
    getTgLnm: getTgLnmCookie,
    setTgLtUp: setTgLtUpCookie,
    getTgLtUp: getTgLtUpCookie,
    setTgCt: setTgCtCookie,
    getTgCt: getTgCtCookie,
    getCareerSetLocal: getCareerCookieSetLocalStorage,
};

export default cookieManager;

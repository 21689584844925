import { siteData } from '../../../data/index';

// our main api key for google analytics
const gaTrackingId = siteData.envSpecificData.googleAnalytics.trackingId;

// event categories
const eventCategoryContact = 'Contact';
const eventCategorySignUp = 'Sign Up';
// const eventCategorySchedule = 'Schedule';
const eventCategoryUserData = 'User Data';

// custom dimensions
const dimension1 = {
    index: 1,
    textName: 'languageId',
};
const dimension2 = {
    index: 2,
    textName: 'userType',
    valueCustomer: 'customer',
    valueTeacherOrAdmin: 'teacheroradmin',
};
const dimension3 = {
    index: 3,
    textName: 'userId',
};

// todo IMPORTANT we need to use document.ready in all calls to ga, because the gtag.js script
//  needs to have loaded before we use any GA call

// inital pageview. if we have the user's id, we send that along with pageview event
function googleAnalyticsInitializeAndPageview(tagData) {
    $(document).ready(() => {
        window.gtag('js', new Date());

        // if we have user id, set it
        if (tagData.haveTgI === true) {
            window.gtag(
                'config',
                `${gaTrackingId}`,
                {
                    custom_map: {
                        dimension1: dimension1.textName,
                        dimension2: dimension2.textName,
                        dimension3: dimension3.textName,
                    },
                    user_id: tagData.tgI,
                },
            );

            // if we don't have user id, that means the user is on the login page/is not logged
            // in, or our ajax failed to get user id, so we need to call ga without it
        } else {
            window.gtag(
                'config',
                `${gaTrackingId}`,
                {
                    custom_map: {
                        dimension1: dimension1.textName,
                        dimension2: dimension2.textName,
                        dimension3: dimension3.textName,
                    },
                },
            );
        }

        // region user data event
        // if we have important data about this user, we'll send that to ga
        const userData = {
            event_category: eventCategoryUserData,
        };
        let haveUserData = false;
        // if we have category
        if (tagData.haveTgCt === true) {
            // user type, if we know that
            if (tagData.tgCt === 100) {
                haveUserData = true;
                userData[dimension2.textName] = dimension2.valueCustomer;
            } else if (tagData.tgCt === 101) {
                haveUserData = true;
                userData[dimension2.textName] = dimension2.valueCustomer;
            } else if (tagData.tgCt === 102) {
                haveUserData = true;
                userData[dimension2.textName] = dimension2.valueCustomer;
            } else if (tagData.tgCt === 103) {
                haveUserData = true;
                userData[dimension2.textName] = dimension2.valueCustomer;

                // user is a teacher or admin
            } else if (tagData.tgCt === 200) {
                haveUserData = true;
                userData[dimension2.textName] = dimension2.valueTeacherOrAdmin;
            }
        }

        // if we have user id, we'll pass that to ga
        if (tagData.haveTgI === true) {
            userData[dimension3.textName] = tagData.tgI;
            haveUserData = true;
        }

        // make the ga event call
        if (haveUserData === true) {
            window.gtag(
                'event',
                'userData',
                userData,
            );
        }
        // endregion
    });
}

// conversion event. contact us form is filled out
function eventContactUs() {
    $(document).ready(() => {
        window.gtag(
            'event',
            'convCtaContactUs',
            {
                event_category: eventCategoryContact,
            },
        );
    });
}

// conversion event. user creates an account
function eventAccountCreated() {
    $(document).ready(() => {
        window.gtag(
            'event',
            'sign_up',
            {
                event_category: eventCategorySignUp,
            },
        );
    });
}

// export
const tagGooogleAnalytics = {
    initAndPageview: googleAnalyticsInitializeAndPageview,
    contactUs: eventContactUs,
    accountCreated: eventAccountCreated,
};

export default tagGooogleAnalytics;

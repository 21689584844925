import * as Sentry from '@sentry/browser';
import { siteData } from '../../../data/index';

// our error logger, sentry
function initialize() {
    Sentry.init({
        dsn: siteData.envSpecificData.sentry.uid,
        environment: siteData.envSpecificData.sentry.environment,
    });
}

// log an error to sentry
function logError(err) {
    try {
        // on local, just log to console
        if (siteData.envSpecificData.environment.env === 'local') {
            /* eslint-disable-next-line no-console */
            console.log('an error occurred:');
            /* eslint-disable-next-line no-console */
            console.log(err);

            // on dev, log to console AND to sentry
        } else if (siteData.envSpecificData.environment.env === 'dev') {
            /* eslint-disable-next-line no-console */
            console.log('an error occurred:');
            /* eslint-disable-next-line no-console */
            console.log(err);
            // log to sentry
            Sentry.captureException(err);

            // in prod, actually log to sentry
        } else {
            Sentry.captureException(err);
        }
    } catch (e) {
        Sentry.captureMessage('BIG ERROR: sentry itself failed to log error');
        Sentry.captureException(e);
    }
}

// log a message to sentry
function logMessage(msg) {
    try {
        // on local, just log to console
        if (siteData.envSpecificData.environment.env === 'local') {
            /* eslint-disable-next-line no-console */
            console.log('message will be logged to sentry:');
            /* eslint-disable-next-line no-console */
            console.log(msg);

            // on dev, log to console and to sentry
        } else if (siteData.envSpecificData.environment.env === 'dev') {
            /* eslint-disable-next-line no-console */
            console.log('message will be logged to sentry:');
            /* eslint-disable-next-line no-console */
            console.log(msg);
            // log to sentry
            Sentry.captureMessage(msg);

            // in prod, actually log to sentry
        } else {
            Sentry.captureMessage(msg);
        }
    } catch (e) {
        Sentry.captureMessage('BIG ERROR: sentry itself failed to log a message');
        Sentry.captureException(e);
    }
}

const logErr = {
    init: initialize,
    msg: logMessage,
    err: logError,
};

export default logErr;

const whyLearn = [
    {
        backendId: 10,
        nameEnglish: 'My career - learning a language will help me do well professionally',
    },
    {
        backendId: 11,
        nameEnglish: 'My school doesn’t offer a foreign language I’m interested in',
    },
    {
        backendId: 12,
        nameEnglish: 'Travel, culture - I want to be able to communicate while I’m exploring',
    },
    {
        backendId: 13,
        nameEnglish: 'I’m taking a class, and want to do well in the class',
    },
    {
        backendId: 14,
        nameEnglish: 'Personal interest, growth, or just for fun!',
    },
    {
        backendId: 15,
        nameEnglish: 'We homeschool, and could use help teaching a foreign language',
    },
    {
        backendId: 16,
        nameEnglish: 'I’m studying a language in school, but over breaks I want to continue learning',
    },
    {
        backendId: 17,
        nameEnglish: 'My family members or friends speak the language, and I want to join in',
    },
    {
        backendId: 18,
        nameEnglish: 'Preparation for a specific exam or certification',
    },
];

module.exports = whyLearn;

import cookieManager from '../modules/cookieManager';
import localSettings from '../modules/localSettings';
import ui from '../modules/ui';
import { siteData } from '../../../data/index';
import ctaContact from '../modules/ctaContact';

let elMainContent;
let elLangLearn;
let elPricesDisplay;
let elPackage;

// main cta contact form
let elFormCtaContact;
let elFormCtaContactEmailAddress;
let elFormCtaContactLangLearn;
let elFormCtaContactSubmitButton;
let elFormSubmitting;
let elFormNotSubmitted;
// we'll set this to true once the contact form is submitted, to prevent doubleclicks
let contactFormSubmitted = false;

function publicInit() {
    _initVars();
    _initListeners();
    _initFunctionality();
}

function _initVars() {
    elMainContent = '[data-js="mainContent"]';
    elLangLearn = '[data-js="langLearn"]';
    elPricesDisplay = '[data-js="pricesDisplay"]';
    elPackage = '[data-js="package"]';

    // main cta contact form
    elFormCtaContact = '[data-js="formCtaContact"]';
    elFormCtaContactEmailAddress = '[data-js="formCtaContactEmailAddress"]';
    elFormCtaContactLangLearn = '[data-js="formCtaContactLangLearn"]';
    elFormCtaContactSubmitButton = '[data-js="formCtaContactSubmitButton"]';
    elFormSubmitting = '[data-js="formSubmitting"]';
    elFormNotSubmitted = '[data-js="formNotSubmitted"]';
}

function _initFunctionality() {
    // hardcode initial language to the first in the list. then attempt to get language from
    // the user's cookies. if it exists, we'll use that to display price
    let currentLangId = siteData.prices[0].langIdFrontend;
    const currentLangLearnCookie = cookieManager.getLangLearnFrontend();
    if (currentLangLearnCookie !== false) {
        // show this lang in the dropdown, prices box
        $(elLangLearn).val(currentLangLearnCookie);
        currentLangId = currentLangLearnCookie;

        // show this lang in the cta
        $(elFormCtaContactLangLearn).val(currentLangLearnCookie);
    }

    // hardcode package to 1 (20 hour package), so that we always show it on pageload
    const currentPackageId = 1;
    _getAndDisplayPrice(currentLangId, currentPackageId);

    // after we've determined the language, set it, and set the price, make both visible
    ui.makeVisible(elLangLearn);
    ui.makeVisible(elPricesDisplay);
}

function _initListeners() {
    _listenLangChange();
    _listenPackageChange();
    _listenLangChangeCta();
    _listenFormSubmit();
}

// listen to changes to the cta form language dropdown
function _listenLangChangeCta() {
    $(elMainContent).on('change', elFormCtaContactLangLearn, (event) => {
        // change local settings, if a value was selected. if there is no value that indicates the
        // user chose the empty option in the dropdown
        const stringLangId = $(event.currentTarget).val();
        if (stringLangId) {
            const langId = parseInt(stringLangId, 10);
            localSettings.language(langId);

            // change the prices box language and update it's prices
            $(elLangLearn).val(langId);
            _updatePricesDisplayedAfterCtaChange(langId);
        }
    });
}

// process the cta form submittal
function _listenFormSubmit() {
    $(elMainContent).on('submit', elFormCtaContact, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        if (contactFormSubmitted === false) {
            contactFormSubmitted = true;

            // disable the button and show loading spinner
            ui.disable(elFormCtaContactSubmitButton);
            ui.hide(elFormNotSubmitted);
            ui.show(elFormSubmitting);

            // the users email address
            const eml = $(elFormCtaContactEmailAddress).val();
            // language selected
            const lang = $(elFormCtaContactLangLearn).val();

            // submit the form
            ctaContact.submitForm(event, eml, lang);
        }
    });
}

// manual changes to the prices box language dropdown
function _listenLangChange() {
    $(elMainContent).on('change', elLangLearn, (event) => {
        const stringLangId = $(event.currentTarget).val();
        const stringPackageId = $(elPackage).val();
        const langId = parseInt(stringLangId, 10);
        const packageId = parseInt(stringPackageId, 10);
        _getAndDisplayPrice(langId, packageId);
        localSettings.language(langId);

        // change the cta value too
        $(elFormCtaContactLangLearn).val(langId);
    });
}

// auto price update after user changes cta box
function _updatePricesDisplayedAfterCtaChange(langId) {
    const stringPackageId = $(elPackage).val();
    const packageId = parseInt(stringPackageId, 10);
    _getAndDisplayPrice(langId, packageId);
    localSettings.language(langId);
}

function _listenPackageChange() {
    $(elMainContent).on('change', elPackage, (event) => {
        const stringPackageId = $(event.currentTarget).val();
        const packageId = parseInt(stringPackageId, 10);
        const stringLangId = $(elLangLearn).val();
        const langId = parseInt(stringLangId, 10);
        _getAndDisplayPrice(langId, packageId);
    });
}

function _getAndDisplayPrice(langId, packageId) {
    const priceText = _getPrice(langId, packageId);
    _displayPrice(priceText);
}

function _getPrice(langId, packageId) {
    let priceText = '';
    siteData.prices.forEach((element) => {
        if (element.langIdFrontend === langId) {
            // 20 hour package
            if (packageId === 1) {
                priceText = element.price30Mins20Hr;

                // 10 hour package
            } else if (packageId === 2) {
                priceText = element.price30Mins10Hr;

                // 5 hour package
            } else if (packageId === 3) {
                priceText = element.price30Mins5Hr;

                // 2 hour package
            } else if (packageId === 4) {
                priceText = element.price30Mins2Hr;
            }
        }
    });

    return priceText;
}

function _displayPrice(priceText) {
    $(elPricesDisplay).html(priceText);
}

export default publicInit;

const languages = require('./en/languages');
const countries = require('./en/countries');
const envSpecificData = require('./envSpecificData');
const url = require('./en/url');
const whoLearning = require('./en/whoLearning');
const whyLearn = require('./en/whyLearn');
const group = require('./en/group');
const prices = require('./prices');

const allData = {
    site: {
        name: 'LanguageConvo',
        description: 'Awesome language teachers, awesome prices...anytime, anywhere! Spanish, French, Mandarin Chinese, and more!',
        // this is used for mobile chrome bar top color
        color: '#338465',
    },
    url,
    languages,
    countries,
    whoLearning,
    whyLearn,
    group,
    prices,
    envSpecificData,
};

// we export this way so that rollup commonjs can use it as a named export
module.exports = { siteData: allData };

import queryString from 'query-string';
import storageManager from './storageManager';
import rand from './rand';
import logErr from './logErr';

// vars
let parameters;
let utmRandomId;
let timestamp;
let currentUtmRecords;

// we get the current page's query string. if it contains utm data, then we store that data
// in localstorage. if utm data already existed in localstorage (from a previous visit to our
// site by this user) then we append this current/new data to that old data
function setUtmRecord() {
    const utmRecord = _createUtmRecordObject();

    // if this page has utm parameters, here we store it in localstorage
    if (_isEmpty(utmRecord) === false) {
        currentUtmRecords = storageManager.getUtmRecords();

        // if there is no existing utm data in localstorage, we simply need to add this new
        // utm data as an array
        if (currentUtmRecords === false) {
            currentUtmRecords = [utmRecord];

            // but if utm data did already exist in localstorage, then we add this new data
            // by array push
        } else {
            currentUtmRecords.push(utmRecord);
        }

        storageManager.setUtmRecords(currentUtmRecords);
    }
}

// create utmRecords object that will be saved in local storage
function _createUtmRecordObject() {
    let hasUtmParameters = false;

    // parameters from query string
    parameters = queryString.parse(window.location.search);

    const utmRecord = {};
    // if any of the utm parameters are provided on page load we will save these utm parameters in
    // local storage
    if (parameters.utm_source) {
        utmRecord.utmSource = parameters.utm_source;
        hasUtmParameters = true;
    }
    if (parameters.utm_medium) {
        utmRecord.utmMedium = parameters.utm_medium;
        hasUtmParameters = true;
    }
    if (parameters.utm_campaign) {
        utmRecord.utmCampaign = parameters.utm_campaign;
        hasUtmParameters = true;
    }
    if (parameters.utm_term) {
        utmRecord.utmTerm = parameters.utm_term;
        hasUtmParameters = true;
    }
    if (parameters.utm_content) {
        utmRecord.utmContent = parameters.utm_content;
        hasUtmParameters = true;
    }

    // query string url has at least one utm parameter which needs to be saved in local storage
    // generate randomUid for utm record and get current timestamp as we only need these variables
    // if utm record needs to be saved in database
    if (hasUtmParameters === true) {
        utmRandomId = rand.randuid();
        // seconds since midnight, 1 Jan 1970
        timestamp = Math.floor(new Date().getTime() / 1000);
        // add randomId generated to utmRecord array, we don't handle this error because randomuid
        // function handles error of randomUid being of correct length otherwise it will throw error
        utmRecord.randomId = utmRandomId;

        // timestamp is an integer value we will save this to utmRecord data
        if (Number.isInteger(timestamp) === true) {
            utmRecord.timestamp = timestamp;
            // timestamp generated is not an integer this is an error we need to know about
        } else {
            const err = new Error('failed to get current timestamp when saving utmRecords');
            logErr.err(err);
        }
    }

    return utmRecord;
}

// tells us if javascript object is empty or not
function _isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

const utmRecords = {
    setUtmRecord,
};

export default utmRecords;

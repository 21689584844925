const whoLearning = [
    {
        backendId: 5,
        nameEnglish: 'An Adult',
    },
    {
        backendId: 6,
        nameEnglish: 'A Child',
    },
    {
        backendId: 7,
        nameEnglish: 'A Group, All Children',
    },
    {
        backendId: 8,
        nameEnglish: 'A Group, All Adults',
    },
    {
        backendId: 9,
        nameEnglish: 'A Group, Mixed Children & Adults',
    },
];

module.exports = whoLearning;

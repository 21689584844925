import cookieManager from '../modules/cookieManager';
import localSettings from '../modules/localSettings';
import ui from '../modules/ui';
import ctaContact from '../modules/ctaContact';

let elMainContent;

// main cta contact form
let elFormCtaContact;
let elFormCtaContactEmailAddress;
let elFormCtaContactLangLearn;
let elFormCtaContactSubmitButton;
let elFormSubmitting;
let elFormNotSubmitted;
// we'll set this to true once the contact form is submitted, to prevent doubleclicks
let contactFormSubmitted = false;

function publicInit() {
    _initVars();
    _initListeners();
    _initFunctionality();
}

function _initVars() {
    elMainContent = '[data-js="mainContent"]';

    // main cta contact form
    elFormCtaContact = '[data-js="formCtaContact"]';
    elFormCtaContactEmailAddress = '[data-js="formCtaContactEmailAddress"]';
    elFormCtaContactLangLearn = '[data-js="formCtaContactLangLearn"]';
    elFormCtaContactSubmitButton = '[data-js="formCtaContactSubmitButton"]';
    elFormSubmitting = '[data-js="formSubmitting"]';
    elFormNotSubmitted = '[data-js="formNotSubmitted"]';
}

function _initFunctionality() {
    // if the user has chosen a language, show that in the cta dropdown
    const currentLangLearnCookie = cookieManager.getLangLearnFrontend();
    if (currentLangLearnCookie !== false) {
        $(elFormCtaContactLangLearn).val(currentLangLearnCookie);
    }
}

function _initListeners() {
    _listenLangChange();
    _listenFormSubmit();
}

// listen to changes to the cta form language dropdown
function _listenLangChange() {
    $(elMainContent).on('change', elFormCtaContactLangLearn, (event) => {
        // change local settings, if a value was selected. if there is no value that indicates the
        // user chose the empty option in the dropdown
        const stringLangId = $(event.currentTarget).val();
        if (stringLangId) {
            const langId = parseInt(stringLangId, 10);
            localSettings.language(langId);
        }
    });
}

// process the cta form submittal
function _listenFormSubmit() {
    $(elMainContent).on('submit', elFormCtaContact, (event) => {
        // stop the default behavior of forms, which is to send the user to a new page
        event.preventDefault();
        if (contactFormSubmitted === false) {
            contactFormSubmitted = true;

            // disable the button and show loading spinner
            ui.disable(elFormCtaContactSubmitButton);
            ui.hide(elFormNotSubmitted);
            ui.show(elFormSubmitting);

            // the users email address
            const eml = $(elFormCtaContactEmailAddress).val();
            // language selected
            const lang = $(elFormCtaContactLangLearn).val();

            // submit the form
            ctaContact.submitForm(event, eml, lang);
        }
    });
}

export default publicInit;

import { siteData } from '../../../data/index';

// define a globally accessible function for recaptcha; recaptcha's api.js script calls this once it
// it has loaded; this in turn renders/creates the recaptcha box on the page
// IMPORTANT: if you want to use recaptcha on a page, you'll need a div that has
//  id = captchaContainer. Then in js for the page, you'll need to load the recaptcha api.js file
window.onloadCaptchaCallback = () => {
    window.grecaptcha.render(
        'captchaContainer',
        { sitekey: siteData.envSpecificData.recaptcha.apiKey },
    );
};

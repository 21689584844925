import { siteData } from '../../../data/index';

// base for external calls
const ajaxUrl = `${siteData.envSpecificData.appUrl.url}/external/`;

// create a user account
function createAccount(dt) {
    return new Promise((resolve, reject) => {
        const data = dt;
        let send = {};
        send.domain = 'external';
        send.action = 'createAccount';
        send.data = data;
        send = JSON.stringify(send);

        $.ajax({
            type: 'POST',
            url: ajaxUrl,
            data: send,
            dataType: 'json',
            success: (result) => {
                if (result.success === 1) {
                    resolve(result.data);
                } else {
                    reject(new Error('signup createAccount did not return success 1'));
                }
            },
            error: (e) => {
                reject(e);
            },
        });
    });
}

const signup = {
    createAccount,
};

export default signup;

const hiddenclass = 'hidden';
const invisibleclass = 'invisible';
const fromAttemptedValidation = 'was-validated';
const formFieldInvalid = 'is-invalid';

/* region hide/visibility */
// hide an element, given its selector
function hideElementBySelector(selector) {
    $(selector).addClass(hiddenclass);
}

// show an element, given its selector
function showElementBySelector(selector) {
    $(selector).removeClass(hiddenclass);
}

// show OR hide an element, given its selector. If the element is currently hidden, this will
// show it, and vice versa
function showHideElement(selector) {
    // if el is already hidden make div unhidden by removing hidden classes
    if (_isElementHidden(selector)) {
        showElementBySelector(selector);
    } else {
        hideElementBySelector(selector);
    }
}

// returns true if an element is hidden, false otherwise
function _isElementHidden(selector) {
    // if el has hidden classes return true
    if ($(selector).hasClass(hiddenclass)) {
        return true;
    }

    return false;
}

function makeElementVisibleBySelector(selector) {
    $(selector).removeClass(invisibleclass);
}

function makeElementInvisibleBySelector(selector) {
    $(selector).addClass(invisibleclass);
}

/* endregion */

/* region forms */

function formAttemptedValidation(selector) {
    $(selector).addClass(fromAttemptedValidation);
}

function setFormFieldInvalid(selector) {
    $(selector).addClass(formFieldInvalid);
}

function removeFormFieldInvalid(selector) {
    $(selector).removeClass(formFieldInvalid);
}

function disableElement(selector) {
    $(selector).prop('disabled', true);
}

function enableElement(selector) {
    $(selector).prop('disabled', false);
}

/* endregion */

const ui = {
    hide: hideElementBySelector,
    show: showElementBySelector,
    showHideElement,
    formAttemptedValidation,
    setFormFieldInvalid,
    removeFormFieldInvalid,
    makeVisible: makeElementVisibleBySelector,
    makeInvisible: makeElementInvisibleBySelector,
    disable: disableElement,
    enable: enableElement,
};

export default ui;

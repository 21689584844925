import { siteData } from '../../../data/index';
import logErr from './logErr';

function frontendLanguageIdConvert(frontendId) {
    const ret = {
        success: false,
        backendId: null,
        nameEnglish: null,
        frontendId: null,
    };
    const frontId = parseInt(frontendId, 10);

    siteData.languages.forEach((element) => {
        if (element.frontendId === frontId) {
            ret.success = true;
            ret.backendId = element.backendId;
            ret.nameEnglish = element.nameEnglish;
            ret.frontendId = frontId;
        }
    });

    if (ret.success === false) {
        logErr.msg(`frontendLanguageIdConvert was passed a frontend language id which was invalid: ${frontId}`);
    }

    return ret;
}

const languages = {
    frontendLanguageIdConvert,
};

export default languages;

const prices = [
    {
        langIdFrontend: 1, // spanish, latin american
        price30Mins20Hr: '9.9',
        price60Mins20Hr: '19.8',
        price30Mins10Hr: '10.15',
        price60Mins10Hr: '20.3',
        price30Mins5Hr: '10.4',
        price60Mins5Hr: '20.8',
        price30Mins2Hr: '10.65',
        price60Mins2Hr: '21.3',
    },
    {
        langIdFrontend: 2, // spanish, european
        price30Mins20Hr: '9.9',
        price60Mins20Hr: '19.8',
        price30Mins10Hr: '10.15',
        price60Mins10Hr: '20.3',
        price30Mins5Hr: '10.4',
        price60Mins5Hr: '20.8',
        price30Mins2Hr: '10.65',
        price60Mins2Hr: '21.3',
    },
    {
        langIdFrontend: 3, // french
        price30Mins20Hr: '9.9',
        price60Mins20Hr: '19.8',
        price30Mins10Hr: '10.15',
        price60Mins10Hr: '20.3',
        price30Mins5Hr: '10.4',
        price60Mins5Hr: '20.8',
        price30Mins2Hr: '10.65',
        price60Mins2Hr: '21.3',
    },
    {
        langIdFrontend: 4, // german
        price30Mins20Hr: '12.4',
        price60Mins20Hr: '24.8',
        price30Mins10Hr: '12.65',
        price60Mins10Hr: '25.3',
        price30Mins5Hr: '12.9',
        price60Mins5Hr: '25.8',
        price30Mins2Hr: '13.15',
        price60Mins2Hr: '26.3',
    },
    {
        langIdFrontend: 5, // italian
        price30Mins20Hr: '12.4',
        price60Mins20Hr: '24.8',
        price30Mins10Hr: '12.65',
        price60Mins10Hr: '25.3',
        price30Mins5Hr: '12.9',
        price60Mins5Hr: '25.8',
        price30Mins2Hr: '13.15',
        price60Mins2Hr: '26.3',
    },
    {
        langIdFrontend: 6, // japanese
        price30Mins20Hr: '12.4',
        price60Mins20Hr: '24.8',
        price30Mins10Hr: '12.65',
        price60Mins10Hr: '25.3',
        price30Mins5Hr: '12.9',
        price60Mins5Hr: '25.8',
        price30Mins2Hr: '13.15',
        price60Mins2Hr: '26.3',
    },
    {
        langIdFrontend: 7, // mandarin
        price30Mins20Hr: '11.4',
        price60Mins20Hr: '22.8',
        price30Mins10Hr: '11.65',
        price60Mins10Hr: '23.3',
        price30Mins5Hr: '11.9',
        price60Mins5Hr: '23.8',
        price30Mins2Hr: '12.15',
        price60Mins2Hr: '24.3',
    },
    {
        langIdFrontend: 8, // portuguese
        price30Mins20Hr: '12.4',
        price60Mins20Hr: '24.8',
        price30Mins10Hr: '12.65',
        price60Mins10Hr: '25.3',
        price30Mins5Hr: '12.9',
        price60Mins5Hr: '25.8',
        price30Mins2Hr: '13.15',
        price60Mins2Hr: '26.3',
    },
    {
        langIdFrontend: 9, // portuguese
        price30Mins20Hr: '12.4',
        price60Mins20Hr: '24.8',
        price30Mins10Hr: '12.65',
        price60Mins10Hr: '25.3',
        price30Mins5Hr: '12.9',
        price60Mins5Hr: '25.8',
        price30Mins2Hr: '13.15',
        price60Mins2Hr: '26.3',
    },
    {
        langIdFrontend: 10, // arabic
        price30Mins20Hr: '11.4',
        price60Mins20Hr: '22.8',
        price30Mins10Hr: '11.65',
        price60Mins10Hr: '23.3',
        price30Mins5Hr: '11.9',
        price60Mins5Hr: '23.8',
        price30Mins2Hr: '12.15',
        price60Mins2Hr: '24.3',
    },
    {
        langIdFrontend: 11, // russian
        price30Mins20Hr: '12.4',
        price60Mins20Hr: '24.8',
        price30Mins10Hr: '12.65',
        price60Mins10Hr: '25.3',
        price30Mins5Hr: '12.9',
        price60Mins5Hr: '25.8',
        price30Mins2Hr: '13.15',
        price60Mins2Hr: '26.3',
    },
    {
        langIdFrontend: 12, // latin
        price30Mins20Hr: '12.4',
        price60Mins20Hr: '24.8',
        price30Mins10Hr: '12.65',
        price60Mins10Hr: '25.3',
        price30Mins5Hr: '12.9',
        price60Mins5Hr: '25.8',
        price30Mins2Hr: '13.15',
        price60Mins2Hr: '26.3',
    },
];

module.exports = prices;

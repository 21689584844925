import { siteData } from '../../../data/index';

// given a language id (must be an integer), validate that it is actually a lang id
function validateLanguageId(langId) {
    let valid = false;
    siteData.languages.forEach((element) => {
        if (element.backendId === langId) {
            valid = true;
        }
    });

    return valid;
}

// given a tag "category" (must be an integer), validate that it is a valid option
function validateTagUserCategory(category) {
    let valid = false;
    if (category === 100 || category === 101 || category === 102 || category === 103
        || category === 200) {
        valid = true;
    }

    return valid;
}

// export
const validations = {
    languageId: validateLanguageId,
    tagUserCategory: validateTagUserCategory,
};

export default validations;
